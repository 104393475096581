import React from 'react'

const SvgDataDistrFeature3 = props => (
  <svg id='dataDistr_feature3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.dataDistr_feature3_svg__st0{fill:#fd5c1f}'}</style>
    <g id='dataDistr_feature3_svg__\u96C6\u6210\u7BA1\u7406-48x48'>
      <g id='dataDistr_feature3_svg__Group_2_' transform='translate(1 1)'>
        <path
          id='dataDistr_feature3_svg__Fill-2_5_'
          className='dataDistr_feature3_svg__st0'
          d='M7.4 14.2l17-9 17 9-17 8.7-10.3-5.4z'
        />
        <path
          id='dataDistr_feature3_svg__Fill-3_3_'
          className='dataDistr_feature3_svg__st0'
          d='M39.4 24.2l-2-1-4 2 2 1z'
        />
        <path
          id='dataDistr_feature3_svg__Fill-4_4_'
          d='M24.4 33.9l-10.3-5.4-8.4-4.3 8.4-4.4 10.3 5.4 10.3-5.4 8.4 4.4-18.7 9.7zm18.7 1.3l-18.7 9.7-18.7-9.7 8.4-4.4 10.3 5.4 10.3-5.4 8.4 4.4zm-37.4-22l18.7-9.7 18.7 9.7-18.7 9.7-10.3-5.4-8.4-4.3zm31.2 5.5l10.5-5.5-23-12-23 12 10.5 5.5-10.5 5.5 10.5 5.5-10.5 5.5 23 12 23-12-10.5-5.5 10.5-5.5-10.5-5.5z'
          fill='#334966'
        />
        <path
          id='dataDistr_feature3_svg__Fill-5_2_'
          className='dataDistr_feature3_svg__st0'
          d='M35.4 37.2l4-2-2-1-4 2z'
        />
      </g>
    </g>
  </svg>
)

export default SvgDataDistrFeature3
