import React from 'react'

const SvgDataDistrFeature2 = props => (
  <svg id='dataDistr_feature2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.dataDistr_feature2_svg__st1{fill:#fd5c1f}'}</style>
    <path
      d='M43.96 4.01c-5.45 0-10.3.92-14.43 2.75-3.4 1.5-6.31 3.61-8.66 6.27-.44.5-.84 1-1.22 1.5-1.81.11-3.66.84-5.5 2.14-1.56 1.1-3.14 2.62-4.67 4.53-2.58 3.2-4.16 6.35-4.23 6.49-.23.44-.1 1 .32 1.31.19.14.41.2.62.2.27 0 .54-.1.74-.3.01-.01 1.02-1 2.73-1.97 1.41-.8 3.58-1.74 6.21-1.91.66 1.71 2.57 3.74 3.48 4.65.91.91 2.95 2.82 4.65 3.49-.17 2.63-1.1 4.81-1.9 6.22-.97 1.71-1.96 2.72-1.96 2.72-.37.37-.41.94-.11 1.36.2.29.52.44.85.44.16 0 .32-.03.47-.11.13-.07 3.29-1.65 6.49-4.24 1.9-1.53 3.42-3.11 4.52-4.67 1.3-1.85 2.02-3.7 2.14-5.51.5-.38 1-.78 1.5-1.22 2.66-2.34 4.76-5.26 6.27-8.66 1.82-4.13 2.74-8.99 2.75-14.43V4l-1.06.01zM15.74 22.93c-2.43.15-4.53.86-6.18 1.67 2-2.9 5.06-6.5 8.5-7.64-1.37 2.38-2.07 4.57-2.32 5.97zm8.68 16.53c.81-1.65 1.52-3.75 1.67-6.18 1.39-.26 3.58-.96 5.97-2.32-1.14 3.44-4.73 6.5-7.64 8.5zM34.6 26.57c-4.37 3.85-8.89 4.72-9.54 4.72-.01 0-.43-.01-1.59-.83-.8-.56-1.75-1.38-2.65-2.28-.9-.9-1.71-1.84-2.28-2.65-.82-1.16-.83-1.59-.83-1.59 0-.65.87-5.17 4.72-9.54 4.61-5.23 11.66-8.08 20.44-8.29-.19 8.79-3.04 15.85-8.27 20.46z'
      fill='#334966'
    />
    <path
      className='dataDistr_feature2_svg__st1'
      d='M13.19 31.2c-3.47 0-6.86 4.51-10.09 13.39-.14.38-.04.82.25 1.1.19.2.46.31.73.31.12 0 .24-.02.36-.06 8.12-2.95 12.58-6.05 13.29-9.22.22-1.01.17-2.53-1.36-4.06-.97-.97-2.04-1.46-3.18-1.46zm2.48 5.06c-.16.73-.82 1.92-3.06 3.46C10.95 40.85 8.7 42 5.9 43.13c1.24-3.09 2.5-5.5 3.75-7.2 1.28-1.72 2.5-2.64 3.54-2.64.58 0 1.13.28 1.69.84.7.71.95 1.4.79 2.13zM31.36 22.9c2.89 0 5.25-2.36 5.25-5.25s-2.36-5.25-5.25-5.25-5.25 2.36-5.25 5.25c.01 2.89 2.36 5.25 5.25 5.25z'
    />
  </svg>
)

export default SvgDataDistrFeature2
