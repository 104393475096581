import React from 'react'
import { graphql } from 'gatsby'
import intl from 'react-intl-universal'
import { SolutionBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, Wrapper } from '@raysync/common/components'
import { LOCALES } from '@raysync/common/services'
import Layout from '../../components/layout'

import { SectionDesc, FeatureSection, Section, SolutionDivider, ArchSection } from './atoms'
import List from '../../components/list'

import Feature1 from '@raysync/common/components/svg/data-distr-feature1'
import Feature2 from '@raysync/common/components/svg/data-distr-feature2'
import Feature3 from '@raysync/common/components/svg/data-distr-feature3'
import Feature4 from '@raysync/common/components/svg/data-distr-feature4'

// import Arch from '@raysync/common/components/svg/solutions/applications/data-distr-arch'
// import ArchEn from '@raysync/common/components/svg/solutions/applications/data-distr-arch-en'
import Banner from '@raysync/common/components/svg/solutions/applications/data-distr-banner'

import Arch from './images/zh/data-distribution.svg'
import ArchEn from './images/en/data-distribution.svg'
class DataDistribution extends React.PureComponent {
  state = {
    featureList: [
      {
        title: intl.get('solution6.feature.item1.title'),
        desc: intl.get('solution6.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('solution6.feature.item2.title'),
        desc: intl.get('solution6.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('solution6.feature.item3.title'),
        desc: intl.get('solution6.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('solution6.feature.item4.title'),
        desc: intl.get('solution6.feature.item4.desc'),
        svg: <Feature4 />,
      },
    ],
  }
  render() {
    const { location } = this.props
    const { featureList } = this.state
    const ArchPic = {
      [LOCALES.ZH]: <img src={Arch} alt={`数据分发`} />,
      [LOCALES.EN]: <img src={ArchEn} alt={`data distribution `} />,
      [LOCALES.JA]: <img src={ArchEn} alt={`data distribution`} />,
    }[intl.options.currentLocale]
    return (
      <Layout pageType='solution6' location={location}>
        <Swiper>
          <SolutionBanner type='solution6' pic={<Banner />} />
        </Swiper>
        <Section>
          <CommonTitle>{intl.get('solution6.section.title')}</CommonTitle>
          <SectionDesc top={25} bottom={18}>
            {intl.get('solution6.section.desc1')}
          </SectionDesc>
          <SectionDesc>{intl.get('solution6.section.desc2')}</SectionDesc>
          <SectionDesc>{intl.get('solution6.section.desc3')}</SectionDesc>
          <SectionDesc bottom={48}>{intl.get('solution6.section.desc4')}</SectionDesc>
          <SolutionDivider />
        </Section>
        <FeatureSection>
          <CommonTitle>{intl.get('solution6.feature.title')}</CommonTitle>
          <SectionDesc top={18} bottom={26}>
            {intl.get('solution6.feature.desc')}
          </SectionDesc>
          <List data={featureList} />
        </FeatureSection>
        <ArchSection>
          <Wrapper>
            <CommonTitle>{intl.get('solution6.arch.title')}</CommonTitle>
            <div style={{ display: 'flex' }}>{ArchPic}</div>
          </Wrapper>
        </ArchSection>
        <Swiper>
          <InviteBanner type='solution6' variant='inquire' />
        </Swiper>
      </Layout>
    )
  }
}

export default DataDistribution

export const query = graphql`
  query {
    adBanner: file(relativePath: { regex: "/industry/ad_banner/" }) {
      ...fluidImage
    }
  }
`
