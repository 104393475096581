import React from 'react'

const SvgDataDistrFeature4 = props => (
  <svg id='dataDistr_feature4_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>
      {
        '.dataDistr_feature4_svg__st0,.dataDistr_feature4_svg__st1{stroke:#2b4b67;stroke-width:2;stroke-miterlimit:10}.dataDistr_feature4_svg__st0{fill:none;stroke-linecap:round}.dataDistr_feature4_svg__st1{fill:#fd5c1f}'
      }
    </style>
    <path
      className='dataDistr_feature4_svg__st0'
      d='M13.13 5.72C16.52 3.38 20.63 2 25.07 2c6.67 0 12.61 3.11 16.45 7.95M16.07 41.99c-7.1-3.37-12-10.6-12-18.98 0-2.18.33-4.29.95-6.27M46.06 23c0 10.24-7.33 18.77-17.02 20.63'
    />
    <circle className='dataDistr_feature4_svg__st1' cx={25.07} cy={44.02} r={3.98} />
    <circle className='dataDistr_feature4_svg__st1' cx={6.89} cy={13.1} r={3.98} />
    <circle className='dataDistr_feature4_svg__st1' cx={43.81} cy={13.34} r={3.98} />
    <path
      id='dataDistr_feature4_svg__Fill-6_3_'
      d='M24.85 16.48l-.64.35c-1.7.93-3.56 1.5-5.49 1.7v6.28c0 1.52.84 2.91 2.19 3.62l3.94 2.06 3.94-2.06c1.35-.7 2.19-2.1 2.19-3.62v-6.28c-1.92-.19-3.79-.77-5.49-1.7l-.64-.35z'
      fill='#fd5c1f'
    />
  </svg>
)

export default SvgDataDistrFeature4
